import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  IconButton,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  Skeleton,
  CircularProgress,
  Container,
  Backdrop,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Helmet } from "react-helmet";


const FailedScreen = ({ handleClose }) => {
  const [referenceId, setReferenceId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setLoading] = useState(false);
 

  const copyToClipboard = () => {
    const textToCopy = `Reference ID: ${referenceId}`;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setOpenSnackbar(true);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get('checkout_id');
    if (refId) {
      setReferenceId(refId);
    }
  }, []);

  return (
    <>
<Helmet>
          <title>PIT - Sign Up | FAILED </title>
        </Helmet>
        {isLoading ? (
        <Container style={{ maxWidth: "1196", padding: "0px" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Skeleton
            variant="rounded"
            className="header"
            style={{ maxwidth: "500", height: "42px", marginBottom: "2rem" }}
          />
          <Skeleton
            variant="rounded"
            style={{ maxwidth: "500", height: "698px", marginBottom: "2rem" }}
          />
          <Skeleton
            variant="rounded"
            style={{ maxwidth: "500", height: "807px", marginBottom: "2rem" }}
          />
        </Container>
      ) : (
        <>
          {" "}
          <div
            style={{
              maxWidth: 1330,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: 100,
            }}
          >
            <img
              src={"/assets/plane.svg"}
              className="plane-icon-lines"
              alt="Plane"
            ></img>

            <div className="video-title">Sign-Up Failed</div>

            <div
              className="video-description"
              style={{
                lineHeight: "125%",
                color: "#320729",
                maxWidth: "759px",
              }}
            >We’ve not been able to process your sign-up but we’re here to help. 
<br/><br/>
            Please contact XXXX@notjusttravel.com, including the payment reference ID below and we’ll be in touch as soon as possible.
            </div>

           

            <div style={{ padding: `48px 5vw 250px 5vw` }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Montserrat",

                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                  maxWidth: "500px",
                }}
              >
                You can copy the following payment reference ID for your
                records:
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: "10px", width: "100%", maxWidth: "500px" }}
              >
                <TextField
                  value={`Reference ID: ${referenceId}`}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={copyToClipboard}>
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              </Box>
            </div>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Reference ID copied to clipboard!
              </Alert>
            </Snackbar>
          </div>
        </>
      )}</>
  );
};

export default FailedScreen;
