import React, { useState, useEffect, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Popover,
  Backdrop,
  useMediaQuery
} from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CustomStepIcon from "./CustomStepIcon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const enquiryQuestions = [
  {
    Title: "Where would you like to go?",
    formFeild: "destination",
    Required: "Y",
    Type: "text",
  },
  {
    Title: "When is your ideal departure date?",
    formFeild: "traveldate",
    Required: "Y",
    Type: "date",
  },
  {
    Title: "How long would you like to be away for?",
    Required: "Y",
    formFeild: "duration",
    Type: "Multiple choice",
    options: ["Less than 7 nights", "7 nights", "7-14 nights", "14+ nights"],
  },
  {
    Title: "How many adults are travelling?",
    formFeild: "adultsCount",
    Required: "Y",
    Type: "Multiple choice",
    options: ["1", "2", "3", "4", "5+"],
  },
  {
    Title: "How many children are travelling?",
    formFeild: "childCount",
    Required: "Y",
    Type: "Multiple choice",
    options: ["0", "1", "2", "3", "4+"],
  },
  {
    Title: "What airport would you like to fly from?",
    formFeild: "prefferedAirport",
    Required: "Y",
    Type: "text",
  },
  {
    Title: "What type of holiday are you looking for?",
    Required: "Y",
    formFeild: "lookingFor",
    Type: "Multiple choice",
    options: [
      "Package",
      "Cruise",
      "Adventure",
      "City Break",
      "Flight only",
      "Other",
    ],
  },
  {
    Title: "Any other message for us?",
    formFeild: "comments",
    Required: "N",
    Type: "text",
  },
  {
    Title: "First name",
    formFeild: "firstName",
    Required: "Y",
    Type: "text",
  },
  {
    Title: "Last name",
    formFeild: "lastName",
    Required: "Y",
    Type: "text",
  },
  {
    Title: "Email address",
    formFeild: "email",
    Required: "Y",
    Type: "email",
  },
  {
    Title: "Phone number (optional)",
    formFeild: "phone",
    Required: "N",
    Type: "number",
  },
  {
    Title: "Best time to contact you",
    Required: "N",
    formFeild: "bestTimeToCall",
    Type: "Multiple choice",
    options: ["Morning", "Afternoon", "Evening"],
  },
];

const steps = enquiryQuestions.map((row) => row.Title);

const EnquiryPopUp = ({ open, handleClose, visibleSteps = 2, pitId }) => {
  const isMobileSM = useMediaQuery("(max-width:500px)");
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageLoading, setLPageLoading] = useState(false);
  const [aggreeToTerms, setAggreeToTerms] = useState(false);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [success, setSuccess] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    // console.log("completed Steps", completedSteps)
    if (!open) {
      setFormData({});
      setErrors({});
      setCompletedSteps([]);
      setActiveStep(0);
      setError(false);
      setValidationError("");
      setSuccess(false);
    }
  }, [open]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeStep, errors]);

  const handleNext = () => {
    setLoading(true);

    if (!validateFormData(activeStep)) {
      setLoading(false);
      return;
    }

    setTimeout(() => {
      setLoading(false);
      setCompletedSteps((prevCompletedSteps) => [
        ...prevCompletedSteps,
        activeStep,
      ]);
      setActiveStep((prevActiveStep) =>
        Math.min(prevActiveStep + 1, steps.length - 1)
      );
    }, 300);
  };

  const handleBack = () => {
    setCompletedSteps((prevCompletedSteps) =>
      prevCompletedSteps.filter((step) => step !== activeStep)
    );
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [activeStep]: "",
    }));
  };

  const handleMultiSelectChange = (event) => {
    const { name, value } = event.target;
    setLoading(true);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [activeStep]: "",
    }));

    setTimeout(() => {
      setLoading(false);
      setCompletedSteps((prevCompletedSteps) => [
        ...prevCompletedSteps,
        activeStep,
      ]);
      setActiveStep((prevActiveStep) =>
        Math.min(prevActiveStep + 1, steps.length - 1)
      );
    }, 300);
  };

  const handleDateChange = (newDate) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      traveldate: dayjs(newDate).format("DD/MM/YYYY"),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [activeStep]: "",
    }));
    setDatePickerOpen(false);
  };

  const handleDateClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleNext();
    }
  };

  const handleEmailValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFormData = (step) => {
    const question = enquiryQuestions[step];
    if (question.Required === "Y") {
      const value = formData[question.formFeild];
      if (!value || value === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [activeStep]: "This field is required",
        }));
        return false;
      }
      if (question.Type === "email" && !handleEmailValidation(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [step]: "Invalid email address",
        }));
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const question = enquiryQuestions[activeStep];
    setLoading(true);
    setLPageLoading(true);

    const value = formData[question.formFeild];
    if (
      question.formFeild === "bestTimeToCall" &&
      !!formData["phone"] &&
      (!value || value === "")
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [activeStep]: "This field is required",
      }));
      return;
    }

    const baseURL =
      process.env.REACT_APP_BACKEND_BASE_URL ||
      "https://vision-pit-backend-dot-seismic-helper-364013.nw.r.appspot.com";

    const apiUrl = `${baseURL}/save_enquiry/`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, pit_associate: pitId }),
      });

      if (response.ok) {
        setSuccess(true);
        setLoading(false);
        setLPageLoading(false);
        setTimeout(() => {
          setSuccess(false);
          handleClose();
        }, 10000);
      } else {
        // Handle validationError response

        console.error("Error submitting form");
        setError(true);
        setLoading(false);
        setLPageLoading(false);
        setTimeout(() => {
          setError(false);
          handleClose();
        }, 10000);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
      setLPageLoading(false);
      console.error("Error submitting form :error", error);
      setTimeout(() => {
        setError(false);
        handleClose();
      }, 10000);
    }
  };

  const renderStepContent = (step) => {
    const question = enquiryQuestions[step];
    const optionsPrefixes = ["A", "B", "C", "D", "E", "F", "G"];

    switch (question.Type) {
      case "text":
      case "number":
      case "email":
        return (
          <>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "18px",
                color: "#81385B",
              }}
            >
              {question.Title} {question?.Required === "Y" ? "*" : ""}
            </Typography>
            <br />
            <TextField
              name={question.formFeild}
              type={question.Type}
              autoFocus
              value={formData[question.formFeild] || ""}
              onChange={handleChange}
              fullWidth
              error={!!errors[step]}
              //helperText={errors[step]}
              onKeyDown={handleKeyDown}
              inputRef={inputRef}
            />
            <Typography variant="body2" sx={{ m: 1 }} color="red">
              {errors[step]}
            </Typography>
          </>
        );
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "18px",
                color: "#81385B",
              }}
            >
              {question.Title} {question?.Required === "Y" ? "*" : ""}
            </Typography>
            <br />

            <DatePicker
              value={
                formData[question.formFeild]
                  ? dayjs(formData[question.formFeild], "DD/MM/YYYY")
                  : null
              }
              onChange={handleDateChange}
              disablePast
              openTo="day"
              format="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors[step]}
                  //helperText={errors[step]}
                  onKeyDown={handleKeyDown}
                  inputRef={inputRef}
                  autoFocus
                />
              )}
              error={!!errors[step]}
            />
            <Typography variant="body2" sx={{ m: 1 }} color="red">
              {errors[step]}
            </Typography>
          </LocalizationProvider>
        );
      case "Multiple choice":
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#81385B",
                }}
              >
                {question.Title} {question?.Required === "Y" ? "*" : ""}
              </Typography>
              <br />
            </FormLabel>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              {question.options.map((option, index) => (
                <Button
                  key={option}
                  variant={
                    formData[question.formFeild] === option
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    handleMultiSelectChange({
                      target: { name: question.formFeild, value: option },
                    });
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "0.5em",
                    flexGrow: 1,
                    backgroundColor:
                      formData[question.formFeild] === option
                        ? "#81385B"
                        : "#fff",
                    textTransform: "none",
                    color:
                      formData[question.formFeild] === option
                        ? "white"
                        : "black",
                    borderColor:
                      formData[question.formFeild] === option
                        ? "#81385B"
                        : "grey.300",
                    "&:hover": {
                      borderColor: "#81385B",
                    },
                  }}

                  // startIcon={<Box
                  //   key={index}
                  //   sx={{
                  //     position: 'relative', left: '5px',
                  //     display: 'flex',
                  //     justifyContent: 'center',
                  //     alignItems: 'center',
                  //     width: '35px',
                  //     height: '35px',
                  //     border: '1px solid blue',
                  //     borderRadius: '4px',
                  //     margin: '0 8px',
                  //     backgroundColor: 'grey.300',
                  //   }}
                  // >
                  //   <Typography variant="body1">{optionsPrefixes[index]}</Typography>
                  // </Box>}
                >
                  {option}
                </Button>
              ))}
            </Box>
            <Typography variant="body2" sx={{ m: 1 }} color="red">
              {errors[step] ? "Select one option" : ""}
            </Typography>
          </FormControl>
        );
      default:
        return `Content for step ${step + 1}`;
    }
  };

  const renderSteps = () => {
    return steps.map((label, index) => {
      if (
        index === 0 ||
        index === steps.length - 1 ||
        (index >= activeStep - visibleSteps &&
          index <= activeStep + visibleSteps)
      ) {
        return (
          <Step key={label}>
            <StepLabel
              StepIconComponent={() => (
                <CustomStepIcon
                  active={index === activeStep}
                  completed={completedSteps.includes(index)}
                  stepNumber={index + 1}
                />
              )}
            ></StepLabel>
          </Step>
        );
      }
      if (
        index === activeStep - visibleSteps - 1 ||
        index === activeStep + visibleSteps + 1
      ) {
        return (
          <Step key={`ellipsis-${index}`}>
            <StepLabel
              StepIconComponent={() => (
                <CustomStepIcon
                  active={index === activeStep}
                  completed={completedSteps.includes(index)}
                  stepNumber={index + 1}
                  hideStep={true}
                />
              )}
            ></StepLabel>
          </Step>
        );
      }
      return null;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
          border: `3px solid #81385B`,
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={pageLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {success ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "2rem",
              textAlign: "center",
            }}
          >
            <img
              src={"/assets/registeration-success.svg"}
              alt="Success"
              style={{
                width: "150px",
                height: "150px",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></img>

            <Typography
              variant="h6"
              color="success"
              sx={{
                fontFamily: "Montserrat",
                marginTop: "20px",
                paddingBottom: "2rem",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Success
            </Typography>

            <Typography
              variant="body1"
              color="success"
              sx={{
                fontFamily: "Montserrat",
                marginTop: "20px",
                paddingBottom: "2rem",
                textAlign: "center",
              }}
            >
              Enquiry Registered Succesfully.
              <br /> Our Team will connect with you based on your request.
            </Typography>
          </div>
        ) : error ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "2rem",
            }}
          >
            <img
              src={"/assets/registeration-failed.svg"}
              alt="Failed"
              style={{
                width: "150px",
                height: "150px",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></img>

            <Typography
              variant="h6"
              color="success"
              sx={{
                fontFamily: "Montserrat",
                marginTop: "20px",
                paddingBottom: "2rem",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Failure
            </Typography>

            <Typography
              variant="body1"
              color="success"
              sx={{
                fontFamily: "Montserrat",
                marginTop: "20px",
                paddingBottom: "2rem",
                textAlign: "center",
              }}
            >
              Enquiry Registration Failed!
              <br />
              Please try again later.
            </Typography>
          </div>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems="center"
              sx={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  fontSize: "24px",
                  color: "#81385B",
                }}
              >
                Make an enquiry
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat",

                  fontSize: "18px",
                }}
              >
                We’ll help plan your holiday from the comfort of home
              </Typography>
            </Box>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              style={{ paddingTop: "1rem" }}
            >
              {renderSteps()}
            </Stepper>
            {activeStep !== 0 && (
              <Button
                onClick={handleBack}
                sx={{ color: "rgb(105, 105, 105)", pl: isMobileSM ? "0rem":"1.5rem", pt: isMobileSM ? "1.5rem":"2.5rem" }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            )}

            <Box sx={{ pl: isMobileSM ? "0rem":"1.5rem", pr: isMobileSM ? ".5rem":"1.5rem", pt: isMobileSM ? ".5rem":"1rem" }}>
              <br />
              {renderStepContent(activeStep)}
              <br />
              {activeStep === steps.length - 1 ? (
                <>
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    alignItems="start"
                    justifyContent={"start"}
                    sx={{ pt: 2, pb: 5 }}
                  >
                    <Checkbox
                      sx={{
                        color: "#81385b",
                        "&.Mui-checked": { color: "#81385b" },
                      }}
                      onChange={(e) => setAggreeToTerms(!!e?.target?.checked)}
                      checked={aggreeToTerms}
                    />
                    <Typography
                      sx={{ fontFamily: "Montserrat", alignContent: "center" }}
                    >
                      I understand you'll contact me with holiday news and offers<span style={{}}>*</span>
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    endIcon={<ArrowForward />}
                    disabled={!aggreeToTerms}
                    sx={{ backgroundColor: "#81385B", color: "#fff", mb: 4 , textTransform: 'none'}}
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  endIcon={
                    loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <ArrowForward />
                    )
                  }
                  sx={{ backgroundColor: "#81385B", color: "#fff", mb: 4 }}
                  disabled={loading}
                >
                  Next
                </Button>
              )}
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EnquiryPopUp;
