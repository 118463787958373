// src/App.js

import React from 'react';

import MainContent from './components/MainContent';
import './App.css';

const App = () => {
  return (
    <div className="App">
      
      <MainContent />
      {/* You can add Footer or other components here */}
    </div>
  );
};

export default App;
