import React from 'react';
import { StepIconProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomStepIcon = ({ active, completed, stepNumber, hideStep }) => {
  const iconStyle = {
    color: active || completed ? '#81385B' : 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    height: 24,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: active  ? '#81385B' : completed ? '#fff' : 'rgba(0, 0, 0, 0.38)',
    color: '#fff',
  };

  return (
    <div style={iconStyle} className='CustomStep'>
      {(completed && !active )? (
        <CheckCircleIcon style={{ color: active  ? '#fff' : completed ? '#81385B' :'#fff' , width: 24, height: 24 }} />
      ) : (
        <div style={{ lineHeight: '24px', fontSize: '12px' }}>
          {hideStep ?'-': stepNumber}
        </div>
      )}
    </div>
  );
};

export default CustomStepIcon;
