import React from "react";
import "./BenefitsSection.css";
import "./images/benefits/plus-circle.svg";

const BenefitsSection = ({ appBenefitsInfo, cards, isMobile,extractTextFromHTML }) => {
  cards?.lines?.sort((a, b) => a.assetline_id - b.assetline_id);

  const getStyledText = (text, word) => {
    const regex = new RegExp(`(${word})`, "gi");
    return text.replace(regex, "<span>$1</span>");
  };

  return (
    <div className="benefits-section">
      {appBenefitsInfo?.lines?.[0]?.primary_image_server_url && (
        <div className="benefits-logo">
          <img
            src={
              appBenefitsInfo?.lines?.[0]?.primary_image_server_url ||
              "/assets/benefits-logo.png"
            }
            className="benefits-holiday-app-img"
            alt="Benefits The Holiday App"
          ></img>
        </div>
      )}

      {appBenefitsInfo?.lines?.[0]?.headline_text && (
        <>{isMobile ? (<div
          className="benefits-title"
          dangerouslySetInnerHTML={{
            __html: getStyledText(
              extractTextFromHTML(appBenefitsInfo?.lines?.[0]?.headline_text),
              "free"
            ),
          }}
        />):(<div
          className="benefits-title"
          dangerouslySetInnerHTML={{
            __html: getStyledText(
              appBenefitsInfo?.lines?.[0]?.headline_text,
              "free"
            ),
          }}
        />)}</>
      )}

      {appBenefitsInfo?.lines?.[0]?.paragraph_text  && (<>
      
      {isMobile ? (<div className="benefits-title-description">
        {extractTextFromHTML(appBenefitsInfo?.lines?.[0]?.paragraph_text) }
      </div>):(<div
          className="benefits-title-description"
          dangerouslySetInnerHTML={{
            __html: 
              appBenefitsInfo?.lines?.[0]?.paragraph_text
            
          }}
        />)}
      
      </>)}

      <div className="benefits-cards">
        <div className="benefits-card">
          <div className="card-image">
            <img
              src={
                cards?.lines?.[0]?.primary_image_server_url ||
                "/assets/card_1.png"
              }
              className="card-image"
              alt="card-cover"
            ></img>
          </div>
          <div className="card-type">
            {cards?.lines?.[0]?.strapline_text || "CHOICE"}
          </div>
          <div className="card-title"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[0]?.headline_text
            
          }}/>
            <div className="card-description"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[0]?.paragraph_text
            
          }}/>
          
        </div>

        <div className="benefits-plus" alt="plus"></div>

        <div className="benefits-card">
          <div className="card-image">
            <img
              src={
                cards?.lines?.[1]?.primary_image_server_url ||
                "/assets/card_2.png"
              }
              className="card-image"
              alt="card-cover"
            ></img>
          </div>
          <div className="card-type">
            {cards?.lines?.[1]?.strapline_text || "VALUE"}
          </div>
          <div className="card-title"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[1]?.headline_text
            
          }}/>
            <div className="card-description"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[1]?.paragraph_text
            
          }}/>
        </div>

        <div className="benefits-plus" alt="plus"></div>

        <div className="benefits-card">
          <div className="card-image">
            <img
              src={
                cards?.lines?.[2]?.primary_image_server_url ||
                "/assets/card_3.png"
              }
              className="card-image"
              alt="card-cover"
            ></img>
          </div>
          <div className="card-type">
            {cards?.lines?.[2]?.strapline_text || "SERVICE"}
          </div>
          <div className="card-title"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[2]?.headline_text
            
          }}/>
            <div className="card-description"  dangerouslySetInnerHTML={{
            __html: 
            cards?.lines?.[2]?.paragraph_text
            
          }}/>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
