// src/components/Header.js

import React from "react";
import "./Header.css";


const Header = ({ headerInfo, enquiryAction }) => {
  return (
    <header className="header">
      <div className="logo">
      <div className="logo-img">
        <img
          src={
           headerInfo?.cms_affiliate_logo_image_link ||
            "/assets/PIT- Marketing Logo.png"    
          }
          alt="PIT LOGO"
          className="PITLogo"
          onClick={()=>{
            const currentUrl = window.location.pathname;
    const pathSegments = currentUrl.split("/");
    // const newUrl = currentUrl + (currentUrl.endsWith("/") ? "" : "/") + append;

    const newUrl =
      `${window.location.origin}` + pathSegments?.[1]? `/${pathSegments?.[1]}` :"" ;

    window.history.pushState({}, "", newUrl);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
          }}
        ></img>
        </div>

        <div className="logoSeperator">
          {/* <SvgLoader name="seperator" /> */}
        </div>

        <div className="logo-container">
          <div className="poweredBy"> Powered By </div>
          <img
            src={"/assets/PIT_landing_powered_by_njt_logo.png"}
            className="powered-by-logo-container"
            alt="NJT LOGO"
          ></img>
        </div>
      </div>

      {enquiryAction && (<div className="cta">
        <span className="">
          <span className="cta-phone-text">
            Call <span style={{fontWeight:700}}>{headerInfo?.cms_name_under_photo_preferred_cond ? `${headerInfo?.cms_name_under_photo_preferred_cond} `:""}</span>{!headerInfo?.cms_name_under_photo_preferred_cond && "your travel consultant "  }on&nbsp;
          </span>

          <a
            href={headerInfo?.cms_preferred_phone ? `tel:${headerInfo?.cms_preferred_phone}`:"tel:+44 01202 978219"}
            className="phoneNumberIconLink"
          >
             <img
          src={
           "https://storage.googleapis.com/njt-cms-media/PIT%20Landing/SVG/phoneicon.svg"   
          }
          alt="Call Button"
          className="phoneNumberIcon"
          ></img>
            {/* <svg
              fill="#81385b"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              id="phone"
              data-name="Line Color"
              xmlns="http://www.w3.org/2000/svg"
              className="icon line-color phoneNumberIcon"
             
            >
              <path
                id="primary"
                d="M21,15v3.93a2,2,0,0,1-2.29,2A18,18,0,0,1,3.14,5.29,2,2,0,0,1,5.13,3H9a1,1,0,0,1,1,.89,10.74,10.74,0,0,0,1,3.78,1,1,0,0,1-.42,1.26l-.86.49a1,1,0,0,0-.33,1.46,14.08,14.08,0,0,0,3.69,3.69,1,1,0,0,0,1.46-.33l.49-.86A1,1,0,0,1,16.33,13a10.74,10.74,0,0,0,3.78,1A1,1,0,0,1,21,15Z"
              ></path>
            </svg> */}
          </a>

          <a
            href={headerInfo?.cms_preferred_phone ? `tel:${headerInfo?.cms_preferred_phone}`:"tel:+44 01202 978219"}
            className="phoneNumber"
          >
            {headerInfo?.cms_preferred_phone || "01202 978219"}
          </a>

          <button className="cta-button" onClick={enquiryAction}>Make an enquiry</button>
        </span>
        
      </div>)}
    </header>
  );
};

export default Header;
