import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import "./BestSection.css";

const BestSection = ({ info, benefits, isMobile, extractTextFromHTML }) => {
 
  const [currentIndex, setCurrentIndex] = useState(0);

  benefits?.lines?.sort((a, b) => a.assetline_id - b.assetline_id);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === benefits.lines.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? benefits.lines.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="best-section">
      <img
        src={info?.lines?.[0]?.primary_image_server_url || "/assets/njt-logo-black-txt.png"}
        className="njt-logo-best-section-img"
        alt="NJT Img"
      />

      {info?.lines?.[0]?.headline_text && (
        <>{isMobile?(<div className="best-section-title">
          {extractTextFromHTML(info?.lines?.[0]?.headline_text)}
        </div>):(<div className="best-section-title" dangerouslySetInnerHTML={{
          __html: info?.lines?.[0]?.headline_text,
        }}
      />
          
       )}</>
      )}
      {info?.lines?.[0]?.paragraph_text && (
       

<>{isMobile?(<div className="best-section-desc">
  {extractTextFromHTML(info?.lines?.[0]?.paragraph_text)}
</div>):(<div className="best-section-desc" dangerouslySetInnerHTML={{
  __html: info?.lines?.[0]?.paragraph_text,
}}
/>
  
)}</>
      )}

      {benefits?.lines?.length > 0 && (
        <div className="best-benefits-tiles-wrapper">
          {isMobile ? (
           <> <div className="best-benefits-carousel">
           <IconButton className="carousel-arrow left-arrow" onClick={handlePrev}>
             <ArrowBackIosNewIcon />
           </IconButton>
           <div className="benefit-card-container">
             <div className="best-benefit-item">
               <div className="column-container">
                 <div className="best-benefit-item-image">
                   <img
                     src={benefits?.lines[currentIndex]?.primary_image_server_url}
                     className=""
                     alt={benefits?.lines[currentIndex]?.headline_text}
                   />
                 </div>
                 <div className="best-benefit-item-title">
                   {benefits?.lines[currentIndex]?.headline_text}
                 </div>
                 <div className="best-benefit-item-description">
                   {benefits?.lines[currentIndex]?.paragraph_text}
                 </div>
               </div>
             </div>
           </div>
           <IconButton className="carousel-arrow right-arrow" onClick={handleNext}>
             <ArrowForwardIosIcon />
           </IconButton>
           
         </div>
         <div className="carousel-dots-best">
                {benefits.lines.map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div></>
          ) : (
            <div className="best-benefits-tiles">
              {benefits?.lines?.map((benefit, key) => (
                <div className="best-benefit-item" key={key}>
                  <div className="column-container">
                    <div className="best-benefit-item-image">
                      <img
                        src={benefit?.primary_image_server_url}
                        className=""
                        alt={benefit?.headline_text}
                      />
                    </div>
                    <div className="best-benefit-item-title">
                      {benefit?.headline_text}
                    </div>
                    <div className="best-benefit-item-description">
                      {benefit?.paragraph_text}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BestSection;
