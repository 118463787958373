import React from 'react';
import Modal from 'react-modal';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { version as pdfjsVersion } from 'pdfjs-dist';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

Modal.setAppElement('#root'); // This is necessary for accessibility

const PdfModal = ({ isOpen, onRequestClose, pdfFile }) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const zoomPluginInstance = zoomPlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const searchPluginInstance = searchPlugin();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="PDF Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: '800px',
                    height: '90%',
                    padding: '20px',
                    position: 'relative', // Ensure the close button is positioned correctly
                },
            }}
        >
            <IconButton
                style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
                onClick={onRequestClose}
            >
                <CloseIcon />
            </IconButton>
            <Box display="flex" flexDirection="column" height="85vh" marginTop="25px">
                <Box style={{ marginBottom: '8px' }}>
                    <Toolbar>
                        {(slots) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* {slots.Search && <slots.Search />} */}
                                    {slots.GoToPreviousPage && <slots.GoToPreviousPage />}
                                    {slots.CurrentPageInput && <slots.CurrentPageInput />}
                                    {slots.NumberOfPages && <slots.NumberOfPages />}
                                    {slots.GoToNextPage && <slots.GoToNextPage />}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {slots.ZoomOut && <slots.ZoomOut />}
                                    {slots.ZoomPopover && <slots.ZoomPopover />}
                                    {slots.ZoomIn && <slots.ZoomIn />}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {slots.Download && <slots.Download />}
                                    {slots.Print && <slots.Print />}
                                    {slots.MoreActionsPopover && <slots.MoreActionsPopover />}
                                </div>
                            </div>
                        )}
                    </Toolbar>
                </Box>
                <Box flex="1 1 auto" style={{ overflow: 'hidden' }}>
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={pdfFile}
                            plugins={[
                                toolbarPluginInstance,
                                zoomPluginInstance,
                                pageNavigationPluginInstance,
                                searchPluginInstance,
                            ]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            style={{ height: '100%', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none' }} // Hide scrollbar
                            className="pdf-viewer"
                        />
                    </Worker>
                </Box>
            </Box>
        </Modal>
    );
};

export default PdfModal;
