import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  fontFamily: "Montserrat",
}));

// ----------------------------------------------------------------------

export default function Page404({ theme }) {
  return (
    <>
      <Helmet>
        <title>Not Just Holidays | Page Not Found</title>
      </Helmet>

     <> <div
        style={{
          maxWidth: 1330,
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: 100,
        }}
      >
        <img
          src={"/assets/plane.svg"}
          className="plane-icon-lines"
          alt="Plane"
        ></img>

        <div className="video-title">Page Not Found</div>

        <div
          className="video-description"
          style={{ lineHeight: "125%", color: "#320729" }}
        >
          It looks like the page you’ve requested doesn’t exist.
          <br />
          <br />
          Please check the spelling of the URL you used.{" "}
        </div>

        <img
          src={"/assets/404_Not_found.png"}
          className="page-not-found"
          alt="404Page"
          style={{ maxHeight: 630, maxWidth: 961, padding: 0, width: "100%" }}
        ></img>
      </div></>
    </>
  );
}
