import React from "react";
import "./AppSection.css";

const AppSection = ({appInfo, getAppNow}) => {
  appInfo?.lines?.sort((a, b) => a.assetline_id - b.assetline_id);
  // console.log("app Info :: ",appInfo)
  return (
    <div className="app-section">
        <div className="app-text">
          <div className="benefit-app-info">{appInfo?.asset_description || "ONE EASY APP"}</div>
          <div className="app-title">
            {( appInfo?.paragraph_text || appInfo?.lines?.[0].headline_text) ||  "Get The Holiday App for free today"}
          </div>
          <div className="app-benefit-list">
            <div className="app-benefit-item">
             

                <img
          src={(appInfo?.lines?.[1]?.primary_image_server_url || "/assets/appInfo-benefits-tick.svg")}
          className="app-benefit-tick tick-img"
          alt="Pointer"
        ></img>

              <div className="app-benefit-desc">
                {appInfo?.lines?.[1]?.headline_text||"Offers you won’t find anywhere else"}
              </div>
            </div>

            <div className="app-benefit-item">
              {/* <div className="app-benefit-tick">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  className="tick-img"
                >
                  <path
                    d="M23.7201 5.8585L20.9025 3.04092C20.8084 2.94873 20.682 2.89709 20.5503 2.89709C20.4186 2.89709 20.2921 2.94873 20.1981 3.04092L8.32401 14.915C8.22995 15.0072 8.10351 15.0588 7.97181 15.0588C7.84011 15.0588 7.71366 15.0072 7.61961 14.915L4.80203 12.0974C4.70798 12.0052 4.58153 11.9536 4.44983 11.9536C4.31814 11.9536 4.19169 12.0052 4.09764 12.0974L1.28006 14.915C1.18787 15.0091 1.13623 15.1355 1.13623 15.2672C1.13623 15.3989 1.18787 15.5253 1.28006 15.6194L7.61961 21.959C7.71366 22.0511 7.84011 22.1028 7.97181 22.1028C8.10351 22.1028 8.22995 22.0511 8.32401 21.959L23.7201 6.5629C23.8123 6.46884 23.8639 6.3424 23.8639 6.2107C23.8639 6.079 23.8123 5.95255 23.7201 5.8585Z"
                    fill="#1F3738"
                  />
                </svg>
              </div> */}
              <img
          src={(appInfo?.lines?.[2]?.primary_image_server_url || "/assets/appInfo-benefits-tick.svg")}
          className="app-benefit-tick tick-img"
          alt="Pointer"
        ></img>
              <div className="app-benefit-desc">
              {appInfo?.lines?.[2]?.headline_text||"Plan any type of holiday you want"}
              </div>
            </div>

            <div className="app-benefit-item">
            <img
          src={(appInfo?.lines?.[3]?.primary_image_server_url || "/assets/appInfo-benefits-tick.svg")}
          className="app-benefit-tick tick-img"
          alt="Pointer"
        ></img>
              <div className="app-benefit-desc">
             {appInfo?.lines?.[3]?.headline_text||"Expert travel advisor ready to help"}
              </div>
            </div>

          </div>
          <button className="benefits-get-app-button" onClick={getAppNow}>Get the app now</button>
          
        </div>

        <div className="app-img-div">
        <img
          src={appInfo?.lines?.[0]?.primary_image_server_url||"/assets/app-image.svg"}
          className="app-img"
          alt="App Img"
          onClick={getAppNow}
        ></img>
        </div>
      </div>
  );
};

export default AppSection;
