import React, { useState , useEffect } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import "./OfferSection.css";

const OfferSection = ({ offersInfo, cards, partnersInfo, getAppNow,extractTextFromHTML, isMobile }) => {
 
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.lines.length - 1 ? 0 : prevIndex + 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards?.lines?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.lines.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(slideInterval);
  }, [currentIndex]);

  return (
    <div className="offers-section">
      <div className="offers-top-image">
        <img
          src={
            offersInfo?.lines?.[0]?.primary_image_server_url ||
            "/assets/offers-header-icons.svg"
          }
          className=""
          alt="Offers"
        />
      </div>
      {offersInfo?.lines?.[0]?.headline_text && (
        <>
        {isMobile ? (<div className="offer-section-title" >{extractTextFromHTML(offersInfo?.lines?.[0]?.headline_text)}</div>):(<div className="offer-section-title"  dangerouslySetInnerHTML={{
          __html: offersInfo?.lines?.[0]?.headline_text,
        }}
      />)}</>
        
      )}
      {offersInfo?.lines?.[0]?.paragraph_text && (
       <>{isMobile?( <div className="offers-section-desc" >{extractTextFromHTML(offersInfo?.lines?.[0]?.paragraph_text)}</div>):( <div className="offers-section-desc"dangerouslySetInnerHTML={{
        __html: offersInfo?.lines?.[0]?.paragraph_text,
      }}
    />)}</>
        
      )}
      <br />
      {offersInfo?.lines?.[0]?.strapline_text && (
        <div className="offers-section-desc-higlight" dangerouslySetInnerHTML={{
          __html: offersInfo?.lines?.[0]?.strapline_text,
        }}
      />
          
      )}

      {cards?.lines?.length > 0 && (
        <div className="offers-cards-wrapper">
          {isMobile ? (
            <><div className="offers-cards-carousel">
            <IconButton
              className="carousel-arrow left-arrow"
              onClick={handlePrev}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <div className="offer-card-container">
              <div className="offer-card">
                <div className="offer-card-image-div">
                  <img
                    src={
                      cards?.lines[currentIndex]?.primary_image_server_url
                    }
                    className="offer-card-image"
                    alt="offer-card-cover"
                  />
                </div>
                <div className="offer-card-type">
                  {cards?.lines[currentIndex]?.strapline_text}
                </div>
                <div className="offer-card-title">
                  {cards?.lines[currentIndex]?.headline_text}
                </div>
                <div className="offer-card-starting-from">
                  {cards?.lines[currentIndex]?.offer_from}
                </div>
                <div className="offer-card-starting-from-price">
                  {cards?.lines[currentIndex]?.offer_price}
                </div>
              </div>
            </div>
            <IconButton
              className="carousel-arrow right-arrow"
              onClick={handleNext}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            
          </div>
          <div className="carousel-dots">
                {cards.lines.map((_, index) => (
                  <span
                    key={index}
                    className={`dot ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div></>
          ) : (
            <div className="offers-cards">
              {cards?.lines?.map((card, key) => (
                <div className="offer-card" key={key}>
                  <div className="offer-card-image-div">
                    <img
                      src={card?.primary_image_server_url}
                      className="offer-card-image"
                      alt="offer-card-cover"
                    />
                  </div>
                  <div className="offer-card-type">{card?.strapline_text}</div>
                  <div className="offer-card-title">{card?.headline_text}</div>
                  <div className="offer-card-starting-from">
                    {card?.offer_from}
                  </div>
                  <div className="offer-card-starting-from-price">
                    {card?.offer_price}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {partnersInfo?.lines?.[0]?.headline_text && (
        <div className="deal-from-brand">
          {partnersInfo?.lines?.[0]?.headline_text}
        </div>
      )}

      {partnersInfo?.lines?.[0]?.primary_image_server_url && (
        <div className="app-info-images">
          <img
            src={partnersInfo?.lines?.[0]?.primary_image_server_url}
            className="brand-img-large"
            alt="Brands Img"
          />
        </div>
      )}

      <button className="offers-join-button join-button" onClick={getAppNow}>
      Get the app now
      </button>
    </div>
  );
};

export default OfferSection;
