import React, { useState } from "react";
import "./Footer.css";
import { ThemeProvider } from '@mui/material/styles';
import PdfModal from './PdfModal';

import NJT_Logo from "./images/njt.png";
import Star from "./images/3d-star.png";

const Footer = ({theme, linkInfo, footerDesc, franchiseMembership, awards}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState('');

  linkInfo?.lines?.sort((a, b) => a.assetline_id - b.assetline_id);

  const openModal = (file) => {
    setPdfFile(file);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPdfFile('');
  };
  return (
    <footer className="footer-section">
      <div className="footer-content">
{/*         
        
        
        <div className="footer-divider"></div> */}

<div className="footer">
  {footerDesc?.lines?.[0]?.paragraph_text && (<div className="footer-logo">
  <div className="footer-logo-awards">
   {awards?.lines?.length>0 && awards.lines.map((line,key)=>( <img
        src={line.primary_image_server_url}
        alt="Awards"
        style={{height:"100%", width:"100%", maxWidth:awards?.lines?.length>0 ? '420px':'100%',maxHeight:awards?.lines?.length>0 ? '100%' :'60px'}}
        key={key}
      ></img>))}
    </div>

   {franchiseMembership?.lines?.[0] && ( <div className="footer-logo-ext">
    <img
        src={franchiseMembership?.lines?.[0]?.primary_image_server_url}
        style={{height:"100%", width:"100%",maxWidth:awards?.lines?.length>0 ? '840px':`100%` }}
        alt="Franchise Memberships"
      ></img>
    </div>)}
    <div className="footer-logo-njt">

      <img
        src={footerDesc?.lines?.[0].primary_image_server_url || "/assets/njt-footer-logo.png"}
        className="njt-footer-logo"
        alt="NJT logo"
      ></img>
    </div>

  </div>)}
 
 {footerDesc?.lines?.[0]?.paragraph_text && ( <div className="footer-desc"  dangerouslySetInnerHTML={{
    __html: footerDesc?.lines?.[0]?.paragraph_text
  }}
/>)}
 
 {linkInfo?.lines?.length >0  && ( <div className="footer-ext-links">
   { linkInfo?.lines?.map((link,key)=>link?.strapline_text && (<button href="#" className="footer-terms" onClick={() => openModal(link?.primary_image_server_url)} key={key}>
      {link?.strapline_text}
    </button>))}
   
  </div>)}

</div>
<ThemeProvider theme={theme}>


<PdfModal isOpen={isModalOpen} onRequestClose={closeModal} pdfFile={pdfFile} />
</ThemeProvider>
</div>
    </footer>
  );
};

export default Footer;
