// src/components/HeroImage.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import { ColorExtractor } from 'react-color-extractor';
import "./HeroSection.css";

const testData = [
  {
    id: "home",
    "hero-url": "/assets/default-hero.png",
    "hero-head": "BECOME A TRAVEL PARTNER",
    "hero-title": "Earn money for holiday referrals",
    "hero-desc":
      "Partners In Travel helps charities raise more funds, businesses reward employees and people make money, just by talking about holidays.",
    background: "#320729",
  },
  {
    id: "charities",
    "hero-url": "/assets/hero-charities.png",
    "hero-head": "BECOME A TRAVEL PARTNER",
    "hero-title": "Raise money with holiday referrals",
    "hero-desc":
      "Partners In Travel helps charities raise more donations from more contributors, quickly and easily, just by talking about holidays.",
    background: "#004A62",
  },
  {
    id: "business",
    "hero-url": "/assets/hero-business.png",
    "hero-head": "BECOME A TRAVEL PARTNER",
    "hero-title": "Increase morale with holiday referrals",
    "hero-desc":
      "Partners In Travel helps businesses reward their staff with better holidays, through discounts on bookings or additional staff perks.",
    background: "#491A00",
  },
  {
    id: "school",
    "hero-url": "/assets/hero-schools.png",
    "hero-head": "BECOME A TRAVEL PARTNER",
    "hero-title": "Support your schoolwith holiday referrals",
    "hero-desc":
      "Partners In Travel helps schools and PTAs quickly and easily raise more money, just by talking about holidays.",
    background: "#333",
  },
];

const HeroImage = ({ heroInfo, action, pitInfo,isMobile,extractTextFromHTML }) => {
  const [backgroundColor, setBackgroundColor] = useState();

  const getColors = (colors) => {
    // Assuming the dominant color is the first one in the array
    setBackgroundColor(colors[0]);
  };

  const getTestData = () => {
    const pathSegments = window.location.pathname.split("/");
    // console.log("pathSeg:", pathSegments);
    // console.log("pathSegments?.[2]  ", pathSegments?.[2])
    if (pathSegments?.[2]) {
      return (
        testData.filter((data) => pathSegments?.[2].includes(data.id))?.[0] ||
        testData.filter((data) => data.id === "home")?.[0]
      );
    } else {
      return testData.filter((data) => data.id === "home")?.[0];
    }
  };

  // const data = getTestData();

  const imageUrl =
   
    (isMobile? ( pitInfo?.cms_mobile_hero_background_link  ) :  pitInfo?.cms_hero_background_link ) || 
    heroInfo?.lines?.[0]?.primary_image_server_url ||
    "/assets/default-hero.png";

  // console.log("Image URL ::", imageUrl);

  return (
    <div
      className="hero-container hero-image-container"
      style={{
        backgroundImage: !isMobile && `url(${imageUrl})`,
      }}
    >
      <Helmet>
        <title>Not Just Holidays {` | Home`}</title>

        {/* <meta name="title" content="Partner In Travel Page | Home" />
        <meta
          name="description"
          content={`Partner In Travel site | Powered By NJT`}
        />
        <meta property="og:title" content={` Partner In Travel Page | Home`} />
        <meta
          property="og:description"
          content="Partner In Travel site | Powered By NJT"
        />
        <meta property="og:image" content={imageUrl} />
        <meta
          property="og:url"
          content={`${
            window.location.origin
          }/${window.location.pathname.replace(/\/$/, "")}`}
        />
        <meta property="og:type" content="Marketing site" />
        
        <meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={`${
            window.location.origin
          }/${window.location.pathname.replace(/\/$/, "")}`} />
<meta property="twitter:title" content="Partner In Travel Page" />
<meta property="twitter:description" content="Partner In Travel site | Powered By NJT" />
<meta property="twitter:image" content={imageUrl} /> */}
      </Helmet>
      {/* <ColorExtractor getColors={getColors}>
        <img
          src={imageUrl}
          alt="Hero"
          crossOrigin="anonymous"
          style={{ display: 'none' }}
        />
      </ColorExtractor> */}
      {/* Content over the hero image if necessary */}
      <div className="hero-content" style={{ background: "#320729" }}>
        <div className="PIT-name">
          {pitInfo?.cms_affiliate_title ||
            heroInfo?.lines?.[0]?.strapline_text ||
            "<<not defined>>"}
        </div>
        {!isMobile ? (
          <div
            className="hero-title"
            dangerouslySetInnerHTML={{
              __html: pitInfo?.cms_affiliate_headline || heroInfo?.lines?.[0]?.headline_text || "<<not defined>>",
            }}
          />
        ) : (
          <div className="hero-title">
            {extractTextFromHTML(
              pitInfo?.cms_affiliate_headline || heroInfo?.lines?.[0]?.headline_text || "<<not defined>>"
            )}
          </div>
        )}
        {!isMobile ? (
          <div
            className="hero-description"
            dangerouslySetInnerHTML={{
              __html: pitInfo?.cms_affiliate_about_notes || heroInfo?.lines?.[0]?.paragraph_text || "<<not defined>>",
            }}
          />
        ) : (
          <div className="hero-description">
            {extractTextFromHTML(
              pitInfo?.cms_affiliate_about_notes || heroInfo?.lines?.[0]?.paragraph_text || "<<not defined>>"
            )}
          </div>
        )}
       
        <div className="hero-cta">
          <button className="hero-cta-button" onClick={action}>
            Find out more
          </button>
        </div>
        <div className="hero-referer">
          <a href="https://www.abta.com/" target="_blank" rel="noreferrer">
            <img
              src={"/assets/ABTA_logo.png"}
              alt="ABTA"
              className="abta-hero-logo"
            />
          </a>
          <a
            href="https://uk.trustpilot.com/review/www.notjusttravel.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={"/assets/Trust_pilot.png"}
              alt="Trust Pilot"
              className="trustpilot-hero-logo"
            />
          </a>
        </div>
      </div>
      <div
        className="hero-image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
      {/* <img className="hero-image"  src= {imageUrl} alt={"hero-background"}></img> */}
    </div>
  );
};

export default HeroImage;
