import React from "react";
import "./TCSection.css";

const TCSection = ({enquiryAction, tcInfo}) => {
  return (
    <div className="tc-section">
      <div className="tc-and-enquiry">
        <div className="tc">
        {tcInfo && (<div className="tc-info">
          <div className="tc-profile-pic">
            <img src={tcInfo?.tc_headshot || "/assets/default-tc-pic.png"} alt="App Img"></img>
          </div>
          <div className="tc-name">{tcInfo?.cms_name_under_photo_preferred_cond}</div>
          <div className="tc-contact-number">{tcInfo?.cms_preferred_phone}</div>
        </div>)}
        <button className="enquiry-button mobile-view" onClick={enquiryAction}>Make an enquiry</button>
        </div>
        <div className="tc-enquiry-section">
          <div className="enquiry-top-red-line"></div>
         {tcInfo && ( <><div className="enquiry-title">
         {tcInfo?.cms_about_tc_headline}
          </div>
          <div className="enquiry-description">
           {tcInfo?.about_tc_notes}
          </div></>)}
          <button className="enquiry-button desktop-view" onClick={enquiryAction}>Make an enquiry</button>
        </div>
      </div>
      <div className="star-rating-list">
        <div className="star-rating">
          <div className="star-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
            >
              <g clipPath="url(#clip0_155_189)">
                <path
                  d="M30.4315 11.2761C30.2967 10.9002 30.0497 10.5749 29.724 10.3439C29.3983 10.1129 29.0095 9.9874 28.6103 9.98442H20.744C20.6113 9.98436 20.4818 9.94341 20.3732 9.86715C20.2645 9.79088 20.182 9.68299 20.1369 9.55817L17.3211 1.54984C17.1744 1.1871 16.9226 0.876484 16.5981 0.657844C16.2736 0.439205 15.8911 0.322517 15.4998 0.322754C15.1019 0.324659 14.7143 0.449895 14.3904 0.681218C14.0666 0.91254 13.8224 1.23857 13.6915 1.61442L10.8627 9.584C10.8176 9.70882 10.7351 9.81671 10.6265 9.89298C10.5179 9.96925 10.3884 10.0102 10.2557 10.0103H2.38942C1.98968 10.0114 1.60008 10.1361 1.27402 10.3674C0.947961 10.5986 0.701409 10.925 0.568165 11.3019C0.440032 11.6745 0.426945 12.077 0.530606 12.4571C0.634267 12.8371 0.849881 13.1773 1.14942 13.4332L7.84025 18.9873C7.94078 19.0694 8.01427 19.1798 8.05113 19.3042C8.08799 19.4286 8.08653 19.5613 8.04692 19.6848L5.23108 28.1194C5.09847 28.51 5.09393 28.9326 5.21814 29.3259C5.34235 29.7192 5.58882 30.0626 5.92169 30.3061C6.25455 30.5496 6.65644 30.6806 7.06888 30.6799C7.48131 30.6792 7.88277 30.547 8.21483 30.3023L15.1123 25.239C15.2221 25.1562 15.3559 25.1114 15.4934 25.1114C15.6309 25.1114 15.7646 25.1562 15.8744 25.239L22.7848 30.3023C23.1143 30.5765 23.5255 30.7336 23.9538 30.7492C24.3822 30.7647 24.8036 30.6378 25.1521 30.3882C25.5006 30.1386 25.7565 29.7806 25.8797 29.37C26.003 28.9595 25.9866 28.5197 25.8332 28.1194L23.0173 19.6848C22.9777 19.5613 22.9763 19.4286 23.0131 19.3042C23.05 19.1798 23.1235 19.0694 23.224 18.9873L29.9278 13.4203C30.218 13.1552 30.4214 12.8087 30.5113 12.4261C30.6011 12.0435 30.5733 11.6426 30.4315 11.2761Z"
                  fill="#E6BD00"
                />
              </g>
              <defs>
                <clipPath id="clip0_155_189">
                  <rect width="31" height="31" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="star-rating-title">
            BEST HOMEWORKING TRAVEL AGENCY
          </div>
          <div className="star-rating-desc">THE CRUISE AWARDS</div>
        </div>

        <div className="star-rating">
          <div className="star-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
            >
              <g clipPath="url(#clip0_155_189)">
                <path
                  d="M30.4315 11.2761C30.2967 10.9002 30.0497 10.5749 29.724 10.3439C29.3983 10.1129 29.0095 9.9874 28.6103 9.98442H20.744C20.6113 9.98436 20.4818 9.94341 20.3732 9.86715C20.2645 9.79088 20.182 9.68299 20.1369 9.55817L17.3211 1.54984C17.1744 1.1871 16.9226 0.876484 16.5981 0.657844C16.2736 0.439205 15.8911 0.322517 15.4998 0.322754C15.1019 0.324659 14.7143 0.449895 14.3904 0.681218C14.0666 0.91254 13.8224 1.23857 13.6915 1.61442L10.8627 9.584C10.8176 9.70882 10.7351 9.81671 10.6265 9.89298C10.5179 9.96925 10.3884 10.0102 10.2557 10.0103H2.38942C1.98968 10.0114 1.60008 10.1361 1.27402 10.3674C0.947961 10.5986 0.701409 10.925 0.568165 11.3019C0.440032 11.6745 0.426945 12.077 0.530606 12.4571C0.634267 12.8371 0.849881 13.1773 1.14942 13.4332L7.84025 18.9873C7.94078 19.0694 8.01427 19.1798 8.05113 19.3042C8.08799 19.4286 8.08653 19.5613 8.04692 19.6848L5.23108 28.1194C5.09847 28.51 5.09393 28.9326 5.21814 29.3259C5.34235 29.7192 5.58882 30.0626 5.92169 30.3061C6.25455 30.5496 6.65644 30.6806 7.06888 30.6799C7.48131 30.6792 7.88277 30.547 8.21483 30.3023L15.1123 25.239C15.2221 25.1562 15.3559 25.1114 15.4934 25.1114C15.6309 25.1114 15.7646 25.1562 15.8744 25.239L22.7848 30.3023C23.1143 30.5765 23.5255 30.7336 23.9538 30.7492C24.3822 30.7647 24.8036 30.6378 25.1521 30.3882C25.5006 30.1386 25.7565 29.7806 25.8797 29.37C26.003 28.9595 25.9866 28.5197 25.8332 28.1194L23.0173 19.6848C22.9777 19.5613 22.9763 19.4286 23.0131 19.3042C23.05 19.1798 23.1235 19.0694 23.224 18.9873L29.9278 13.4203C30.218 13.1552 30.4214 12.8087 30.5113 12.4261C30.6011 12.0435 30.5733 11.6426 30.4315 11.2761Z"
                  fill="#E6BD00"
                />
              </g>
              <defs>
                <clipPath id="clip0_155_189">
                  <rect width="31" height="31" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="star-rating-title">
            BEST BESPOKE TRAVEL CONSULTANCY
          </div>
          <div className="star-rating-desc">EUROPEAN TRAVEL AWARDS</div>
        </div>

        <div className="star-rating">
          <div className="star-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
            >
              <g clipPath="url(#clip0_155_189)">
                <path
                  d="M30.4315 11.2761C30.2967 10.9002 30.0497 10.5749 29.724 10.3439C29.3983 10.1129 29.0095 9.9874 28.6103 9.98442H20.744C20.6113 9.98436 20.4818 9.94341 20.3732 9.86715C20.2645 9.79088 20.182 9.68299 20.1369 9.55817L17.3211 1.54984C17.1744 1.1871 16.9226 0.876484 16.5981 0.657844C16.2736 0.439205 15.8911 0.322517 15.4998 0.322754C15.1019 0.324659 14.7143 0.449895 14.3904 0.681218C14.0666 0.91254 13.8224 1.23857 13.6915 1.61442L10.8627 9.584C10.8176 9.70882 10.7351 9.81671 10.6265 9.89298C10.5179 9.96925 10.3884 10.0102 10.2557 10.0103H2.38942C1.98968 10.0114 1.60008 10.1361 1.27402 10.3674C0.947961 10.5986 0.701409 10.925 0.568165 11.3019C0.440032 11.6745 0.426945 12.077 0.530606 12.4571C0.634267 12.8371 0.849881 13.1773 1.14942 13.4332L7.84025 18.9873C7.94078 19.0694 8.01427 19.1798 8.05113 19.3042C8.08799 19.4286 8.08653 19.5613 8.04692 19.6848L5.23108 28.1194C5.09847 28.51 5.09393 28.9326 5.21814 29.3259C5.34235 29.7192 5.58882 30.0626 5.92169 30.3061C6.25455 30.5496 6.65644 30.6806 7.06888 30.6799C7.48131 30.6792 7.88277 30.547 8.21483 30.3023L15.1123 25.239C15.2221 25.1562 15.3559 25.1114 15.4934 25.1114C15.6309 25.1114 15.7646 25.1562 15.8744 25.239L22.7848 30.3023C23.1143 30.5765 23.5255 30.7336 23.9538 30.7492C24.3822 30.7647 24.8036 30.6378 25.1521 30.3882C25.5006 30.1386 25.7565 29.7806 25.8797 29.37C26.003 28.9595 25.9866 28.5197 25.8332 28.1194L23.0173 19.6848C22.9777 19.5613 22.9763 19.4286 23.0131 19.3042C23.05 19.1798 23.1235 19.0694 23.224 18.9873L29.9278 13.4203C30.218 13.1552 30.4214 12.8087 30.5113 12.4261C30.6011 12.0435 30.5733 11.6426 30.4315 11.2761Z"
                  fill="#E6BD00"
                />
              </g>
              <defs>
                <clipPath id="clip0_155_189">
                  <rect width="31" height="31" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="star-rating-title">
            SUSTAINABILITY CHAMPION OF THE YEAR
          </div>
          <div className="star-rating-desc">TRAVEL WEEKLY</div>
        </div>
      </div>
    </div>
  );
};

export default TCSection;
