

import React, { forwardRef } from "react";
import "./VideoSection.css";

const VideoSection = forwardRef((data, ref) => {
  const {
    videoSectionInfo,
    isMobile,
    benefits = {},
    wistiaBlock,
    handleRegistration,
    extractTextFromHTML,
  } = data;

  const video = wistiaBlock?.lines?.[0]?.wistia_embed_block;

  benefits?.lines?.sort((a, b) => a.assetline_id - b.assetline_id);

  return (
    <div className="video-section" ref={ref && ref}>
      <img
        src={
          videoSectionInfo?.lines?.[0]?.primary_image_server_url ||
          "/assets/plane.svg"
        }
        className="plane-icon-lines"
        alt="Plane"
      ></img>

      {videoSectionInfo?.lines?.[0].headline_text && (
        <>
          {isMobile ? (
            <div className="video-title">
              {extractTextFromHTML(videoSectionInfo?.lines?.[0].headline_text)}
            </div>
          ) : (
            <div
              className="video-title"
              dangerouslySetInnerHTML={{
                __html: videoSectionInfo?.lines?.[0].headline_text,
              }}
            />
          )}
        </>
      )}

      {videoSectionInfo?.lines?.[0].paragraph_text && (
        <>
          {isMobile ? (
            <div className="video-description">
              {extractTextFromHTML(videoSectionInfo?.lines?.[0].paragraph_text)}
            </div>
          ) : (
            <div
              className="video-description"
              dangerouslySetInnerHTML={{
                __html: videoSectionInfo?.lines?.[0].paragraph_text,
              }}
            />
          )}
        </>
      )}

      {video && (
       <div className="video-container"> <div
       className="video-ref-link"
       dangerouslySetInnerHTML={{ __html: video }}
     ></div></div>
      )}

     <div className="video-tiles">
        <div className="video-benefits-tiles">
          {benefits?.lines?.length > 0 &&
            benefits.lines.map((benefit, index) => (
              <div className="video-benefit-item" key={index}>
                <div key={index} className="column-container-video">
                  <div className="video-benefit-item-image">
                    <img
                      src={
                        benefit?.primary_image_server_url //|| "/assets/refer.svg"
                      }
                      alt={benefit?.headline_text}
                    />
                  </div>
                  {benefit?.headline_text && (
                    <div
                      className="video-benefit-item-title"
                      dangerouslySetInnerHTML={{
                        __html: benefit?.headline_text,
                      }}
                    />
                  )}
                  {benefit?.paragraph_text && (
                    <div
                      className="video-benefit-item-description"
                      dangerouslySetInnerHTML={{
                        __html: benefit?.paragraph_text,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* <button
        className="get-app-button"
        onClick={() => {
          handleRegistration();
        }}
      >
        Get the app now
      </button> */}
    </div>
  );
});

export default VideoSection;
