import React from "react";
import "./Testimonial.css";

const Testimonial = ({testimonialInfo}) => {
  return (
    <div className="testimonial">
        <div className="testimonial-quotes">
        “          
        </div>

        <div className="testimonial-description">
        {testimonialInfo?.lines?.[0]?.paragraph_text||"A great personal service to booking our holiday, listened to our needs and provided options with explanations. What impressed me the most was they touched base before and during the holiday to make sure everything was ok. Already booking next years holiday!"}
        </div>
        <div className="testimonial-name">
        {testimonialInfo?.lines?.[0]?.strapline_text || "Testimonial name"}
        </div>
      </div>
  );
};

export default Testimonial;
