import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import { styled } from "@mui/material/styles";
import {
  Button,
  Typography,
  Container,
  Box,
  Backdrop,
  CircularProgress,
  Skeleton,
  Snackbar,
  IconButton,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Page404 from "./Page404";
import SuccessScreen from "./SuccessScreen";
import FailedScreen from "./FailedScreen";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  fontFamily: "Montserrat",
}));

// ----------------------------------------------------------------------

export default function Info({ pageType, theme, pitLandingPageInfo, isLoading }) {
 
const [pageLoading, setPageLoading] = useState(isLoading);
const [displayError, setDisplayError] = useState(false);
const [cmsData, setCmsData] = useState(pitLandingPageInfo);
  const isMobile = useMediaQuery('(max-width:600px)');

  const findAssest = (sortOrder) => {
    return (pitLandingPageInfo?.landing_data?.assets|| cmsData?.[0]?.assets)?.filter(
      (asset) => asset?.sort_order === sortOrder
    )?.[0];
  };

  useEffect(() => {
   if(!pitLandingPageInfo || pitLandingPageInfo?.length===0){
   setPageLoading(true);
   
   

    const baseURL =
      process.env.REACT_APP_BACKEND_BASE_URL ||
      "https://vision-pit-backend-dot-seismic-helper-364013.nw.r.appspot.com";

    
      fetch(`${baseURL}/footer_data/`)
        .then((response) => 
          {
          
         return response.json();
    })
        .then((data) => {
          
          setCmsData(data);
         
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          setDisplayError(true);
        });
    } else {
     
      setPageLoading(false);
    }
   }
  , []);



  return (
    <>
      {pageLoading ? (
        <Container style={{ maxWidth: "1440px", padding: "0px" }}>
          <Container style={{ maxWidth: "1196", padding: "0px" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={pageLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

           
            <Skeleton
              variant="rounded"
              style={{
                maxWidth: "1196px",
                height: "698px",
                marginBottom: "2rem",
              }}
            />
            <Skeleton
              variant="rounded"
              style={{
                maxWidth: "1196px",
                height: "807px",
                marginBottom: "2rem",
              }}
            />
          </Container>
        </Container>
      ) : (
        <>
          <Container style={{ maxWidth: "1440px", padding: "0px" }}>
            <Container style={{ maxWidth: "1300px", padding: "0px" }}>
              <Header
              headerInfo={pitLandingPageInfo?.landing_data?.pit_info}
              />
            </Container>
          </Container>
          <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={displayError}
          autoHideDuration={3000}
          onClose={()=>setDisplayError(false)}
          message={"Error in fetch the CMS data"}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setDisplayError(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

          <div style={{backgroundColor:"#FDF9F4",minHeight:100}}>
          {pageType === "404" ? (
            <Page404 theme={theme} />
          ) : pageType === "success" ? (
            <SuccessScreen theme={theme} wistiaBlock={findAssest(300)}/>
          ) : (
            <FailedScreen theme={theme} />
          )}
          </div>
          <div style={{
  background: '#101010' , minHeight:isMobile? 800:300}}>
          <Footer
            linkInfo={findAssest(3000)}
            theme={theme}
            footerDesc={findAssest(2600)}
            franchiseMembership={findAssest(2500)}
            awards={findAssest(2400)}
          />
          </div>
       
        </>
      )}
    </>
  );
}
