import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Box,
  IconButton,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
  Skeleton,
  CircularProgress,
  Container,
  Backdrop,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Helmet } from "react-helmet";
import AvailableOnAppStore from "./images/AvailableOnAppStore.png";
import GetItOnPlayStore from "./images/GetItOnPlayStore.png";


const SuccessScreen = ({ isLoading}) => {

 
  return (
    <>
      <Helmet>
        <title>App Registration | SUCCESS</title>
      </Helmet>
      {isLoading ? (
        <Container style={{ maxWidth: "1196", padding: "0px" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Skeleton
            variant="rounded"
            className="header"
            style={{ maxwidth: "500", height: "42px", marginBottom: "2rem" }}
          />
          <Skeleton
            variant="rounded"
            style={{ maxwidth: "500", height: "698px", marginBottom: "2rem" }}
          />
          <Skeleton
            variant="rounded"
            style={{ maxwidth: "500", height: "807px", marginBottom: "2rem" }}
          />
        </Container>
      ) : (
        <>
          {" "}
          <div
            style={{
              maxWidth: 1330,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: 100,
            }}
          >
            <img
              src={"/assets/holiday-app.png"}
              // className="benefits-holiday-app-img"
              style={{width: "70px",
                height: "71px", paddingTop:56}}
              alt="benefits-holiday-app-logo"
            ></img>

            <div className="video-title">Download The <br/>Holiday App Now</div>

            <div
              className="video-description"
              style={{
                lineHeight: "125%",
                color: "#320729",
                maxWidth:754
              }}
            >
              Thanks for registering for The Holiday App!<br/><br/> You’re now just one tap away from fantastic holiday offers, inspiration and personal service from your very own travel consultant.
            </div>

            <div
              className="video-description"
              style={{
                lineHeight: "125%",
                color: "#320729",
                maxWidth:754,
                fontWeight:700
              }}
            >
          Tap to download now  </div>

          <div className="downloadIcons">
              <a
                href="https://apps.apple.com/in/app/the-holiday/id6553999522"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AvailableOnAppStore}
                  alt="AvailableOnAppStore"
                  className="AvailableOnAppStore"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.app.travel_partner_app&hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GetItOnPlayStore}
                  alt="GetItOnPlayStore"
                  className="GetItOnPlayStore"
                />
              </a>
            </div>

            <img
              src={"/assets/AppImage.png"}
              // className="benefits-holiday-app-img"
              style={{maxWidth: 508,maxHeight:621,width:"100%",
                height: "100%", paddingTop:45}}
              alt="benefits-holiday-app-phone-pic"
            ></img>

           
         
          </div>
        </>
      )}
    </>
  );
};

export default SuccessScreen;
